import $ from "jquery";

window.JAM = window.JAM || {};
window.JAM.pages = window.JAM.pages || {};

window.JAM.pages.landingPage = (() => {
  const PAGE_SELECTOR = ".landing-pages-show";

  const init = () => {
    const $page = $(PAGE_SELECTOR);

    if (!$page.length) {
      return;
    }

    JAM.components.common.showLoadersOnFormSubmit();
    JAM.components.fetchCustomerMsisdn.init();
    handleAutoRedirect();
  };

  const handleAutoRedirect = () => {
    const $pageData = $("#page-data");
    const autoRedirect = $pageData.data("auto-redirect");
    const autoRedirectDelay = $pageData.data("auto-redirect-delay");

    if (autoRedirect == "true") {
      setTimeout(() => {
        JAM.components.common.showLoader();
        $landingPagesShow.find("form").first().trigger("submit");
      }, autoRedirectDelay || 1000);
    }
  };

  return {
    init,
  };
})();

$(function () {
  window.JAM.pages.landingPage.init();
});
