import $ from "jquery";

window.JAM = window.JAM || {};
window.JAM.components = window.JAM.components || {};

window.JAM.components.fetchCustomerMsisdn = (() => {
  let $pageData;
  let safaricomAccessToken;
  let msisdnInputSelector;
  let encryptedMsisdnInputSelector;
  let partnerId;
  let formSelector;

  const init = () => {

    $pageData = $("#page-data");
    partnerId = $pageData.data("dot-partner-id");
    safaricomAccessToken = $pageData.data("safaricom-access-token");
    formSelector = "#msisdn-form";
    msisdnInputSelector = "#msisdn-input";
    encryptedMsisdnInputSelector = "#encrypted-msisdn-input";

    const networkCode = $("#network-code").val();

    if (networkCode == "safaricom") {
      fetchSafaricomCustomerMsisdn();
    } else {
      fetchZongCustomerMsisdn();
    }
  };

  /**
   * Fetches the customer's MSISDN using the Safaricom network.
   *
   * Makes a GET request to the Safaricom HE API to retrieve a masked MSISDN
   * for the customer using the provided access token. On a successful response,
   * populates the MSISDN input field and submits the form. Displays an error
   * message if the request fails or the MSISDN is not retrieved.
   */

  const fetchSafaricomCustomerMsisdn = () => {
    const API_URL = `${JAM.vars.SAFARICOM_HE_API_URL}`;

    fetch(API_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${safaricomAccessToken}`,
        "Content-Type": "application/json",
        "X-App": "he-partner",
        "X-MessageID": crypto.randomUUID(),
        "X-Source-System": "he-partner",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const msisdn = data.ServiceResponse.ResponseBody.Response.Msisdn;
        const responseCode = data.ServiceResponse.ResponseHeader.ResponseCode;

        if (responseCode == 200 && msisdn) {
          $(msisdnInputSelector).val(msisdn);
          JAM.components.common.hideLoader();
          submitForm();
        } else {
          handleRequestFailure();
        }
      })
      .catch(() => {
        handleRequestFailure();
      });
  };

  const handleRequestFailure = () => {
    $("#reload-button-container").removeClass("d-none");
    window.JAM.components.common.showFlashMessage(
      "Please switch to Safaricom network and try again",
      "error"
    );
    
  };

  $(function() {
    $("#reload-button").on("click", function() {
      location.reload(); 
    });
  });

  /**
   * Fetches the customer's MSISDN using the Zong network.
   *
   * Makes a GET request to the Zong HTTP API to retrieve an encrypted MSISDN
   * for the customer using the provided partner ID. On a successful response,
   * calls the handleFetchMsisdnSuccess function with the retrieved MSISDN.
   * Calls the handleFetchMsisdnFailure function if the request fails or the
   * MSISDN is not retrieved.
   */
  const fetchZongCustomerMsisdn = () => {
    const xhr = new XMLHttpRequest();

    xhr.open(
      "GET",
      `${JAM.vars.DOT_HTTP_API_URL}/appgw/GetPartnerHEMSISDN?partnerId=${partnerId}`,
      true
    );

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const status = xhr.status;

        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully
          const responseJson = JSON.parse(xhr.responseText);
          const responseCode = responseJson["errorCode"];

          if (responseCode == 0) {
            const msisdn = responseJson["encMSISDN"];

            if (msisdn) {
              handleFetchMsisdnSuccess(msisdn);
            } else {
              handleFetchMsisdnFailure();
            }
          } else {
            handleFetchMsisdnFailure();
          }
        } else {
          // Oh no! There has been an error with the request!
          handleFetchMsisdnFailure();
        }
      }
    };

    xhr.send();
  };

  const handleFetchMsisdnSuccess = (msisdn) => {
    $(encryptedMsisdnInputSelector).val(msisdn);
    $(msisdnInputSelector).removeAttr("required");

    const token = document.getElementsByName("csrf-token")[0].content;

    $.ajax({
      url: `${window.location.pathname}/track_visit`,
      method: "POST",
      headers: {
        "X-CSRF-TOKEN": token,
      },
      data: {
        encrypted_msisdn: msisdn,
      },
      complete: () => {
        submitForm();
        JAM.components.common.hideLoader();
      },
    });
  };

  const handleFetchMsisdnFailure = () => {
    $(msisdnInputSelector).removeClass("d-none");

    JAM.components.common.hideLoader();

    window.JAM.components.common.showFlashMessage(
      "Please enter your mobile number",
      "success"
    );
  };

  const submitForm = () => {
    $(formSelector).find("input[type=submit").first().trigger("click");
  };

  return {
    init,
  };
})();
